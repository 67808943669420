<template>
	<div id="newsDetails">
		<Header title="" description="" />
		<div v-if="newsDetails" class="relative z-50 -top-24 md:-top-52 2xl:-top-60 -mb-24 md:-mb-48">
			<div class="w-11/12 md:w-3/4 mx-auto px-5 lg:px-12">
				<img
					data-aos="fade-up"
					data-aos-duration="600"
					data-aos-easing="ease-in-out"
					class="w-full border border-secondry object-cover"
					style="max-height: 560px"
					alt=""
					loading="lazy"
					:src="'https://' + newsDetails.photo[0].path"
				/>
				<div class="w-3/4 mx-auto pt-12">
					<div class="flex items-center text-dateText my-1 tracking-wide w-full">
						<span>{{ $i18n.locale == 'ar' ? newsDetails.category_ar : newsDetails.category_en }} - </span>
						<span class="mx-1">
							{{ $moment(newsDetails.created_at).format('YYYY-MM-DD') }}
						</span>
					</div>
					<h1 class="text-footerBg text-2xl my-4 font-bold w-full">
						{{ $i18n.locale == 'ar' ? newsDetails.title_ar : newsDetails.title_en }}
					</h1>
					<p
						v-html="$i18n.locale == 'ar' ? newsDetails.description_ar : newsDetails.description_en"
						class="text-newsDescription py-5 font-medium tracking-wide text-lg"
					></p>
				</div>
			</div>
			<div v-if="lastNews.length > 0" class="bg-readmoreBG">
				<div class="container mx-auto px-5 lg:px-8 pt-8">
					<h1 class="text-Text text-3xl font-medium w-full">
						<span> {{ $t('news.readmore') }}</span>
						<span class="block w-16 bg-info my-1 rounded-lg h-1"></span>
					</h1>
					<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 py-12 sm:gap-12 lg:gap-20">
						<NewsComponent
							v-for="(item, index) in lastNews"
							:key="index"
							:news="item"
							class="col-auto lg:col-span-1"
							:data-aos="$i18n.locale == 'EN' ? 'fade-left' : 'fade-right'"
							data-aos-duration="600"
							data-aos-easing="ease-in-out"
							:data-aos-delay="index == 1 ? '200' : index == 2 ? '350' : '0'"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="flex items-center justify-center my-12">
			<i class="bx bx-loader-circle bx-spin text-5xl text-primary"></i>
		</div>
	</div>
</template>
<script>
import Header from '@/components/layout/Header';
import NewsComponent from '@/components/utils/NewsComponent.vue';

export default {
	name: 'newsDetails',
	data() {
		return {
			URL: process.env.VUE_APP_URL,
		};
	},
	watch: {
		rout_id() {
			this.$store.dispatch('fetchNewsDetails', this.rout_id);
		},
	},
	created() {
		this.$store.dispatch('fetchNewsDetails', this.rout_id);
	},
	computed: {
		newsDetails() {
			return this.$store.getters.newsDetails;
		},
		lastNews() {
			return this.$store.getters.lastNews;
		},
		rout_id() {
			return this.$route.params.id;
		},
	},
	components: {
		Header,
		NewsComponent,
	},
};
</script>
